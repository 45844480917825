<template>
  <div class="profile">
    <ProfileHeader :profile="profile" />

    <v-row>
      <v-col
        cols="12"
      >
        <v-card class="profile-messages">
          <v-card-title>
            Unread Messages
          </v-card-title>
          <div
            v-if="unreadMessageList.length"
            style="max-height:398px; overflow:auto"
          >
            <ThreadList
              :message-list="unreadMessageList"
            />
          </div>
          <v-card-text v-else>
            No unread messages.
          </v-card-text>
          <v-card-actions>
            <v-btn :to="{ name: 'MessageInbox' }">
              View inbox
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        v-if="imageEvidence.length"
        cols="12"
        md="6"
      >
        <EvidenceLocker :evidence-list="imageEvidence" />
      </v-col>
      <v-col
        v-if="profile.achievements.length"
        cols="12"
        md="6"
      >
        <AchievementList :achievements="profile.achievements" />
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <v-card>
          <v-card-title>
            Recruits
          </v-card-title>
          <v-card-text>
            If a member enters your username in the referrer field when registering for Quest, or registers via your referrer URL, you'll earn points.
          </v-card-text>
          <LeaderList
            v-if="referrals.length"
            max-height="338px"
            :entry-list="referrals"
            :load-more="false"
          />
          <v-card-actions>
            <v-btn @click="shareReferralURL">
              Share Referral URL
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col
        v-if="profile.allow_shop"
        cols="12"
        md="6"
      >
        <ShoppingInfoTile />
      </v-col>
    </v-row>

    <v-snackbar
      v-model="snackbar"
      timeout="2000"
    >
      {{ snackbarText }}
    </v-snackbar>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import AchievementList from '@/components/AchievementList.vue'
import EvidenceLocker from '@/components/EvidenceLocker.vue'
import LeaderList from '@/components/LeaderList.vue'
import ProfileHeader from '@/components/ProfileHeader.vue'
import ShoppingInfoTile from '@/components/ShoppingInfoTile.vue'
import ThreadList from '@/components/ThreadList.vue'

export default {
  components: {
    AchievementList,
    EvidenceLocker,
    LeaderList,
    ProfileHeader,
    ShoppingInfoTile,
    ThreadList,
  },
  data: function() {
    return {
      snackbar: null,
      snackbarText: null,
    }
  },
  computed: {
    ...mapState({
      profile: state => state.auth.profile,
      referrals: state => state.referrals.all,
    }),
    ...mapGetters({
      unreadMessageList: 'messages/unreadMessageList',
      imageEvidence: 'participants/imageEvidence',
    }),
  },
  created: function () {
    this.$store.dispatch('auth/getProfile')
    this.$store.dispatch('messages/getAll')
    this.$store.dispatch('referrals/getAll')
  },
  methods: {
    shareReferralURL() {
      const registerRoute = this.$router.resolve({name: 'Register'})
      const url = `${this.$domain}${registerRoute.href}/?referrer=${this.profile.user}`
      try {
        navigator.share({
          title: 'Join Quest',
          url: url,
        })
          .catch(() => this.copyURL(url));
      } catch (error) {
        this.copyURL(url)
      }
    },
    copyURL(url) {
      this.$copyText(url).then(() => {
        this.snackbarText = 'The URL was successfully copied to your clipboard.'
        this.snackbar = true
      })
    },
  },
}
</script>
